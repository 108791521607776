<template>
  <div class="pageContol homeWork">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">作业列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc"
          style="dispaly: flex; align-items: flex-start"
        >
          <div class="searchbox">
            <!-- <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            > -->
            <el-button
              type="primary"
              class="bgc-bv"
              round
              :disabled="projectStudyTimeState == '60'"
              @click="assignHomework('add')"
              >新增作业</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="布置日期"
                align="center"
                show-overflow-tooltip
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="截止时间" align="center" prop="deadline">
                <template slot-scope="scope">
                  {{ scope.row.deadline | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="作业内容"
                align="center"
                show-overflow-tooltip
                prop="taskContent"
              >
                <template slot-scope="scope">
                  {{ scope.row.taskContent }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="assignHomework('edit', scope.row.courseTaskId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="handleDel(scope.row.courseTaskId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="look(scope.row.courseTaskId)"
                    >查看作业</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 布置作业 -->
    <el-dialog
      title="布置作业"
      :visible.sync="assignHomeworkDialog"
      width="61%"
      top="1%"
      :center="true"
      @close="homeWorkCancel"
    >
      <div class="ovy-a" style="height: 500px">
        <el-form
          :model="HomeworkForm"
          :rules="rules"
          ref="HomeworkForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="作业内容:" prop="taskContent">
            <el-input
              v-model="HomeworkForm.taskContent"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="上传作业图片："
            prop="questionbankBanner"
            class="bannerBox"
          >
            <el-upload
              action
              :file-list="HomeworkForm.fileList"
              list-type="picture-card"
              :on-change="handlePictureCardPreview"
              :before-upload="beforeAvatarUpload"
              :http-request="$requestMine"
              limit="6"
              class="df"
              :class="{ hide: hideUploadCard }"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="提交截止时间:" prop="deadline">
            <el-date-picker
              v-model="HomeworkForm.deadline"
              type="datetime"
              size="small"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择提交截止时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="提交截止前提醒:" prop="deadlineRemind">
            <el-switch v-model="HomeworkForm.deadlineRemind"> </el-switch>
          </el-form-item>
          <el-form-item label="提交后可查看答案:" prop="submitViewanswer">
            <el-switch v-model="HomeworkForm.submitViewanswer"></el-switch>
          </el-form-item>
          <el-form-item label="作业答案:" prop="taskAnswer">
            <el-input
              v-model="HomeworkForm.taskAnswer"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="上传作业答案图片："
            prop="questionbankBanner"
            class="bannerBox"
          >
            <el-upload
              action
              :file-list="HomeworkForm.fileAnswersList"
              list-type="picture-card"
              :on-change="handlePictureCardAnswers"
              :before-upload="beforeAvatarUploadAnswers"
              :http-request="$requestMine"
              limit="6"
              class="df"
              :class="{ hide: hideUploadCardAnswers }"
              :on-remove="handleRemoveAnswers"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="footer"
        style="display: flex; justify-content: center"
      >
        <div class="btnBox">
          <el-button class="bgc-bv" @click="homeWorkCancel">取消</el-button>
          <el-button class="bgc-bv" @click="homeWorkDoOk">确认</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "assignHomework",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      assignHomeworkDialog: false, //布置作业弹窗
      courseTaskId: "", //作业Id
      HomeworkForm: {
        taskContent: "", //作业内容
        deadline: "", //截止时间
        deadlineRemind: false, //截止提醒
        submitViewanswer: false, //查看答案
        taskAnswer: "", //作业答案
        thumbnail: [],
        srcImg: [],
        fileList: [],
        fileAnswersList: [], //作业答案图片数组
        srcImgAnswers: [], //作业答案图片url数据
        thumbnailAnswers: [], //作业答案图片Key数据
      },
      rules: {
        taskContent: [
          { required: true, message: "请输入作业内容", trigger: "blur" },
        ],
        deadline: [
          { required: true, message: "请选择提交截止时间", trigger: "change" },
        ],
      },
      hideUploadCard: false,
      hideUploadCardAnswers: false,
      projectStudyTimeState: "",
    };
  },
  computed: {},
  methods: {
    init() {
      this.projectStudyTimeState = sessionStorage.getItem(
        "projectStudyTimeState"
      );
      this.getTableHeight();
      this.getData(-1);
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectCourseId: this.$route.query.projectCourseId,
        courseId: this.$route.query.courseId,
        projectId: this.$route.query.projectId,
      };
      this.doFetch({
        url: "/biz/course/task/pageList",
        params,
        pageNum,
      });
    },
    // 删除
    handleDel(courseTaskId) {
      this.doDel({
        url: "/biz/course/task/delete",
        msg: "确定删除该作业吗？",
        ps: {
          type: "post",
          data: { courseTaskId },
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //布置作业
    assignHomework(stu, courseTaskId = "") {
      this.assignHomeworkDialog = true;
      if (stu == "edit") {
        this.courseTaskId = courseTaskId;
        this.getAssignHomeWork(courseTaskId);
      } else {
        this.courseTaskId = "";
      }
    },
    getAssignHomeWork(courseTaskId) {
      this.$post("/biz/course/task/getInfo", { courseTaskId }).then((res) => {
        let retData = res.data;
        this.HomeworkForm.taskContent = retData.taskContent;
        this.HomeworkForm.deadline = retData.deadline.replaceAll("/", "-");
        this.HomeworkForm.deadlineRemind = retData.deadlineRemind;
        this.HomeworkForm.submitViewanswer = retData.submitViewanswer;
        this.HomeworkForm.thumbnail = retData.appendixKeyList || [];
        this.HomeworkForm.thumbnailAnswers =
          retData.appendixAnswerKeyList || [];
        this.HomeworkForm.taskAnswer = retData.taskAnswer;
        if (retData.appendixKeyList) {
          for (var i = 0; i < retData.appendixKeyList.length; i++) {
            this.HomeworkForm.fileList.push({
              name: retData.appendixKeyList[i],
              url: retData.appendixList[i],
            });
          }
        }
        if (retData.appendixAnswerKeyList) {
          for (var i = 0; i < retData.appendixAnswerKeyList.length; i++) {
            this.HomeworkForm.fileAnswersList.push({
              name: retData.appendixAnswerKeyList[i],
              url: retData.appendixAnswerList[i],
            });
          }
        }

        this.hideUploadCard =
          this.HomeworkForm.thumbnail.length == 6 ? true : false;
        this.hideUploadCardAnswers =
          this.HomeworkForm.thumbnailAnswers.length == 6 ? true : false;
      });
    },
    /* 上传图片start */
    handlePictureCardPreview(res, fileList) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("folder ", "COURSE_TASK");
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
        fileList.splice(-1, 1); //移除选中图片
        return false;
      }
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传后缀为.jpg或.png或.jpeg或.gif格式的图片");
        for (let i = 0; i < fileList.length; i++) {
          if (
            ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
              fileList[i].raw.type
            ) === -1
          ) {
            fileList.splice(i, 1);
          }
        }
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.HomeworkForm.srcImg.push(result.data.fileURL);
            this.HomeworkForm.thumbnail.push(result.data.fileKey);
            this.hideUploadCard =
              this.HomeworkForm.thumbnail.length == 6 ? true : false;
          })
          .catch(() => {
            return;
          });
      }
    },

    /* 上传图片end */
    // },
    /* 删除图片start */
    handleRemove(res, fileList) {
      this.HomeworkForm.thumbnail = [];
      fileList.map((item) => {
        this.HomeworkForm.thumbnail.push(item.name);
      });
      this.hideUploadCard = fileList.length == 6 ? true : false;
    },
    /* 删除图片end */

    /* 上传作业答案图片start */
    handlePictureCardAnswers(res, fileList) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("folder ", "COURSE_TASK");
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1M!");
        fileList.splice(-1, 1); //移除选中图片
        return false;
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传后缀为.jpg或.png或.jpeg或.gif格式的图片");
        for (let i = 0; i < fileList.length; i++) {
          if (
            ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
              fileList[i].raw.type
            ) === -1
          ) {
            fileList.splice(i, 1);
          }
        }
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.HomeworkForm.srcImgAnswers.push(result.data.fileURL);
            this.HomeworkForm.thumbnailAnswers.push(result.data.fileKey);
            this.hideUploadCardAnswers =
              this.HomeworkForm.thumbnailAnswers.length == 6 ? true : false;
          })
          .catch(() => {
            return;
          });
      }
    },
    /* 上传图片end */
    /* 删除图片start */
    handleRemoveAnswers(res, fileAnswersList) {
      this.HomeworkForm.thumbnailAnswers = [];
      fileAnswersList.map((item) => {
        this.HomeworkForm.thumbnailAnswers.push(item.name);
      });
      this.hideUploadCardAnswers = fileAnswersList.length == 6 ? true : false;
    },
    /* 删除图片end */
    //布置作业 -- 确认
    homeWorkDoOk() {
      this.$refs.HomeworkForm.validate((valid) => {
        if (valid) {
          const that = this;
          let parameter = {
            taskContent: that.HomeworkForm.taskContent, //作业内容
            deadline: that.HomeworkForm.deadline, //截止时间
            deadlineRemind: that.HomeworkForm.deadlineRemind, //截止提醒
            submitViewanswer: that.HomeworkForm.submitViewanswer, //提交查看答案
            appendixList: that.HomeworkForm.thumbnail, //课程作业题目集合
            appendixAnswerList: that.HomeworkForm.thumbnailAnswers, //课程作业答案集合
            taskAnswer: that.HomeworkForm.taskAnswer, //作业答案
            courseId: that.$route.query.courseId,
            projectCourseId: that.$route.query.projectCourseId,
            projectId: that.$route.query.projectId,
          };
          if (that.courseTaskId) {
            parameter.courseTaskId = that.courseTaskId;
          }
          that
            .$post(
              that.courseTaskId
                ? "/biz/course/task/modify"
                : "/biz/course/task/insert",
              parameter
            )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.homeWorkCancel();
                this.getData(-1);
              } else {
                this.$message({
                  message: ret.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    //布置作业 -- 取消
    homeWorkCancel() {
      this.$refs["HomeworkForm"].resetFields();
      this.assignHomeworkDialog = false;
      this.courseTaskId = "";
      this.HomeworkForm = {
        taskContent: "", //作业内容
        deadline: "", //截止时间
        deadlineRemind: false, //截止提醒
        submitViewanswer: false, //查看答案
        taskAnswer: "", //作业答案
        thumbnail: [],
        srcImg: [],
        fileList: [],
        fileAnswersList: [], //作业答案图片数组
        srcImgAnswers: [], //作业答案图片url数据
        thumbnailAnswers: [], //作业答案图片Key数据
      };
      this.hideUploadCard = false;
      this.hideUploadCardAnswers = false;
    },
    //查看作业
    look(courseTaskId) {
      this.$router.push({
        path: "/web/assignHomeworkLook",
        query: {
          projectCourseId: this.$route.query.projectCourseId,
          courseId: this.$route.query.courseId,
          projectId: this.$route.query.projectId,
          courseTaskId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-textarea /deep/.el-textarea__inner {
  min-height: 100px !important;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card i {
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
